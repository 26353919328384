import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Link, graphql } from 'gatsby'

import Layout from '../parts/layout'
import Alternatives from "../parts/alternatives"


const DomainTemplate = ({ data }) => {
  const alternatives = data.strapiDomain.alternatives.map(alternative => {
    return data.allStrapiAlternative.nodes.find(node => node.title === alternative.title)
  })

  return (
    <Layout>
      <div className="row">
        <h1>{data.strapiDomain.title}</h1>
        <div className="content">{ ReactHtmlParser(data.strapiDomain.description) }</div>
        { data.strapiDomain.alternatives.length !== 0 && <>
          <h3>Related initiatives</h3>
          <Alternatives alternatives={ alternatives } />
        </> }
        <Link to="/domains/" className="back">&laquo; all domains</Link>
      </div>
    </Layout>
  )
}

export default DomainTemplate

export const query = graphql`query DomainTemplate($id: String!) {
  strapiDomain(id: {eq: $id}) {
    id
    title
    description
    alternatives {
      title
    }
  }
  allStrapiAlternative {
    nodes {
      id
      title
      description
      link
      logo {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
      domains {
        slug
        title
      }
      layers {
        slug
        title
      }
    }
  }
}
`